import { Navigate, useRoutes } from 'react-router-dom';
import MainLayout from '../layouts/main';
import CompactLayout from '../layouts/compact';
import { HomePage, Page404, Page500 } from './elements';

export default function Router() {
  return useRoutes([
    // Main layout
    {
      element: <MainLayout />,
      children: [{ element: <HomePage />, index: true }],
    },
    // Compact layout
    {
      element: <CompactLayout />,
      children: [
        { path: '500', element: <Page500 /> },
        { path: '404', element: <Page404 /> },
      ],
    },
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}
