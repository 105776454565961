import React, { forwardRef, useCallback } from 'react';
// @mui
import { Link } from '@mui/material';
// components
import Iconify from 'src/components/iconify';
//
import { useNavigateOrScroll } from 'src/hooks/useNavigateOrScroll';
import { NavItemProps } from '../types';
import { StyledNavItem } from './styles';

// ----------------------------------------------------------------------

export const NavItem = forwardRef<HTMLDivElement, NavItemProps>(
  ({ item, open, active, subItem, isExternalLink, ...other }, ref) => {
    const { title, path, children } = item;

    const navigateOrScroll = useNavigateOrScroll();

    const clickHandler = useCallback(
      (event: React.MouseEvent): void => {
        event.preventDefault();

        navigateOrScroll(path);
      },
      [path, navigateOrScroll]
    );

    const renderContent = (
      <StyledNavItem
        ref={ref}
        disableRipple
        subItem={subItem}
        active={active}
        open={open}
        {...other}
      >
        {title}

        {!!children && <Iconify width={16} icon="carbon:chevron-down" sx={{ ml: 1 }} />}
      </StyledNavItem>
    );

    // ExternalLink
    if (isExternalLink) {
      return (
        <Link href={path} target="_blank" rel="noopener" color="inherit" underline="none">
          {renderContent}
        </Link>
      );
    }

    // Has child
    if (children) {
      return renderContent;
    }

    // Default
    return (
      <Link href={path} onClick={clickHandler} color="inherit" underline="none">
        {renderContent}
      </Link>
    );
  }
);
