import React, { useCallback, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Button, Drawer, IconButton, List, Stack } from '@mui/material';
import { NAV } from 'src/config-global';
import Logo from 'src/components/logo';
import Iconify from 'src/components/iconify';
import Scrollbar from 'src/components/scrollbar';
import {
  NavMobileProvider,
  useNavMobileContext,
} from 'src/layouts/main/nav/mobile/NavMobileContext';
import { useNavigateOrScroll } from 'src/hooks/useNavigateOrScroll';
import { NavProps } from '../types';
import NavList from './NavList';

export default function NavMobile({ data }: NavProps) {
  return (
    <NavMobileProvider>
      <NavMobileInner data={data} />
    </NavMobileProvider>
  );
}

function NavMobileInner({ data }: NavProps) {
  const { open, setOpen } = useNavMobileContext();
  const { pathname } = useLocation();

  useEffect(() => {
    if (open) {
      handleClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const navigateOrScroll = useNavigateOrScroll();

  const contactUsHandler = useCallback(
    (event: React.MouseEvent): void => {
      event.preventDefault();
      navigateOrScroll('#contact-us');
    },
    [navigateOrScroll]
  );

  return (
    <>
      <IconButton onClick={handleOpen} sx={{ ml: 1, color: 'inherit' }}>
        <Iconify icon="carbon:menu" />
      </IconButton>

      <Drawer
        open={open}
        onClose={handleClose}
        PaperProps={{
          sx: {
            pb: 5,
            width: NAV.W_BASE,
          },
        }}
      >
        <Scrollbar>
          <Logo sx={{ mx: 2.5, my: 3 }} />

          <List component="nav" disablePadding>
            {data.map((link) => (
              <NavList key={link.title} item={link} />
            ))}
          </List>

          <Stack spacing={1.5} sx={{ p: 3 }}>
            <Button
              href="#contact-us"
              fullWidth
              variant="contained"
              color="inherit"
              onClick={contactUsHandler}
            >
              Contact Us
            </Button>
          </Stack>
        </Scrollbar>
      </Drawer>
    </>
  );
}
