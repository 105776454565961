import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useScrollAndHighlightElement } from 'src/hooks/useScrollAndHighlightElement';

export const useNavigateOrScroll = (): ((path: string) => void) => {
  const scrollAndHighlight = useScrollAndHighlightElement();
  const navigate = useNavigate();

  return useCallback(
    (path: string): void => {
      if (path.startsWith('#')) {
        scrollAndHighlight(path.slice(1), { offset: -32 });
      } else {
        navigate(path);
      }
    },
    [navigate, scrollAndHighlight]
  );
};
