// @mui
import { Link, ListItemIcon, ListItemText } from '@mui/material';
// components
import Iconify from 'src/components/iconify';
//
import React, { useCallback } from 'react';
import { useNavMobileContext } from 'src/layouts/main/nav/mobile/NavMobileContext';
import { useNavigateOrScroll } from 'src/hooks/useNavigateOrScroll';
import { NavItemProps } from '../types';
import { StyledNavItem } from './styles';

// ----------------------------------------------------------------------

export default function NavItem({ item, open, active, isExternalLink, ...other }: NavItemProps) {
  const { title, path, icon, children } = item;
  const { setOpen: setNavOpen } = useNavMobileContext();

  const navigateOrScroll = useNavigateOrScroll();

  const clickHandler = useCallback(
    (event: React.MouseEvent): void => {
      event.preventDefault();

      setNavOpen(false);

      navigateOrScroll(path);
    },
    [path, setNavOpen, navigateOrScroll]
  );

  const renderContent = (
    <StyledNavItem active={active} {...other}>
      <ListItemIcon> {icon} </ListItemIcon>

      <ListItemText disableTypography primary={title} />

      {!!children && (
        <Iconify
          width={16}
          icon={open ? 'carbon:chevron-down' : 'carbon:chevron-right'}
          sx={{ ml: 1 }}
        />
      )}
    </StyledNavItem>
  );

  // ExternalLink
  if (isExternalLink) {
    return (
      <Link href={path} target="_blank" rel="noopener" underline="none">
        {renderContent}
      </Link>
    );
  }

  // Has child
  if (children) {
    return renderContent;
  }

  // Default
  return (
    <Link href={path} onClick={clickHandler} underline="none">
      {renderContent}
    </Link>
  );
}
