import { Box, IconButton } from '@mui/material';
import Iconify from '../../../iconify';

type Props = {
  open: boolean;
  onToggle: VoidFunction;
};

export default function ToggleButton({ open, onToggle }: Props) {
  return (
    <Box sx={{ position: 'relative' }}>
      <IconButton color="inherit" aria-label="settings" onClick={onToggle}>
        <Iconify icon="carbon:light" />
      </IconButton>
    </Box>
  );
}
