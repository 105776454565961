import { Box, IconButton } from '@mui/material';
import { useSettingsContext } from 'src/components/settings/SettingsContext';
import { useMemo } from 'react';
import Iconify from 'src/components/iconify';

export default function ToggleModeButton() {
  const { themeMode, onToggleMode } = useSettingsContext();

  const isLight = useMemo<boolean>(() => themeMode === 'light', [themeMode]);

  return (
    <Box sx={{ position: 'relative' }}>
      <IconButton color="inherit" aria-label="settings" onClick={onToggleMode}>
        <Iconify icon={isLight ? 'carbon:moon' : 'carbon:sun'} />
      </IconButton>
    </Box>
  );
}
