import { createContext, useContext, useMemo, useState } from 'react';

export type NavMobileContextProps = {
  open: boolean;
  setOpen: (open: boolean) => void;
};

const initialState: NavMobileContextProps = {
  open: false,
  setOpen: (open: boolean) => {},
};

// ----------------------------------------------------------------------

export const NavMobileContext = createContext(initialState);

export const useNavMobileContext = () => {
  const context = useContext(NavMobileContext);

  if (!context) throw new Error('useNavMobileContext must be use inside NavMobileProvider');

  return context;
};

type NavMobileProviderProps = {
  children: React.ReactNode;
};

export function NavMobileProvider({ children }: NavMobileProviderProps) {
  const [open, setOpen] = useState(false);

  const memoizedValue = useMemo(
    () => ({
      open,
      setOpen,
    }),
    [open, setOpen]
  );

  return <NavMobileContext.Provider value={memoizedValue}>{children}</NavMobileContext.Provider>;
}
