import { useCallback } from 'react';
import { scroller } from 'react-scroll';
import { useTheme } from '@mui/material/styles';
import { highlightElement } from 'src/utils/highlightUtils';

export type HighlightAndScrollOptions = {
  containerId?: string;
  offset?: number;
};

export const useScrollAndHighlightElement = (): ((
  elementId: string,
  options?: HighlightAndScrollOptions
) => void) => {
  const theme = useTheme();

  return useCallback(
    (elementId: string, options?: HighlightAndScrollOptions): void => {
      scroller.scrollTo(elementId, {
        duration: 800,
        delay: 0,
        smooth: 'easeInOutQuart',
        offset: options?.offset,
        isDynamic: true,
        containerId: options?.containerId,
      });

      highlightElement(elementId, theme.palette.divider);
    },
    [theme]
  );
};
