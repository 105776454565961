import { memo } from 'react';

import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box, BoxProps, Link } from '@mui/material';

// ----------------------------------------------------------------------

interface LogoProps extends BoxProps {
  single?: boolean;
}

function Logo({ single = false, sx }: LogoProps) {
  const theme = useTheme();

  const PRIMARY_MAIN = theme.palette.primary.main;

  const singleLogo = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      fill="none"
      viewBox="0 0 136.94 88.03"
    >
      <circle fill={PRIMARY_MAIN} cx="126.28" cy="58.69" r="10.66" />
      <path fill="currentColor" d="M44.14,26.57h-14.06v42.85H15.51V26.57H1.29V14.57H44.14v12Z" />
      <path
        fill="currentColor"
        d="M111.68,14.57v54.85h-13.97V36.6l-16.46,21.17-16.37-21.08v32.74h-13.97V14.57h13.2l17.14,24.43,17.14-24.43h13.28Z"
      />
    </svg>
  );

  const fullLogo = (
    <svg
      xmlns="http://www.w3.org/2000/svg "
      width="100%"
      height="100%"
      fill="none"
      viewBox="0 0 136.94 88.03"
    >
      <circle fill={PRIMARY_MAIN} cx="126.28" cy="58.69" r="10.66" />
      <path fill="currentColor" d="M44.14,26.57h-14.06v42.85H15.51V26.57H1.29V14.57H44.14v12Z" />
      <path
        fill="currentColor"
        d="M111.68,14.57v54.85h-13.97V36.6l-16.46,21.17-16.37-21.08v32.74h-13.97V14.57h13.2l17.14,24.43,17.14-24.43h13.28Z"
      />
    </svg>
  );

  return (
    <Link
      component={RouterLink}
      to="/"
      color="inherit"
      aria-label="go to homepage"
      sx={{ lineHeight: 0 }}
    >
      <Box
        sx={{
          width: single ? 100 : 75,
          lineHeight: 0,
          cursor: 'pointer',
          display: 'inline-flex',
          ...sx,
        }}
      >
        {single ? singleLogo : fullLogo}
      </Box>
    </Link>
  );
}

export default memo(Logo);
